import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Footer, Header } from '../../components'

const VisitorLayout = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0
        });
    }, [pathname]);

    return (
        <>
            <Header />
            <Outlet />
            <Footer />
        </>
    )
}

export default VisitorLayout