import React from 'react'
import { Accordion, Table } from 'react-bootstrap'
import { formatDateTime } from '../../../../utils'
import { Check } from '@mui/icons-material';
import "./style.scss";

const UserReservationDetailsAccordion = ({ pickUpLocation, dropOffLocation, pickUpTime, dropOffTime, status, totalPrice, car }) => {
    return (
        <Accordion defaultActiveKey={0} className='details-accordion'>
            <Accordion.Item eventKey={0}>
                <Accordion.Header>
                    Reservation Details
                </Accordion.Header>
                <Accordion.Body>
                    <Table striped bordered hover>
                        <tbody>
                            <tr>
                                <td>Pick Up Location</td>
                                <td>{pickUpLocation}</td>
                            </tr>
                            <tr>
                                <td>Drop Off Location</td>
                                <td>{dropOffLocation}</td>
                            </tr>
                            <tr>
                                <td>Pick Up Time</td>
                                <td>{formatDateTime(pickUpTime)}</td>
                            </tr>
                            <tr>
                                <td>Drop Off Time</td>
                                <td>{formatDateTime(dropOffTime)}</td>
                            </tr>
                            <tr>
                                <td>Status</td>
                                <td>{status}</td>
                            </tr>
                            <tr>
                                <td>Price</td>
                                <td>${totalPrice}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey={1}>
                <Accordion.Header>Vehicle Details</Accordion.Header>
                <Accordion.Body>
                    <Table striped bordered hover>
                        <tbody>
                            <tr>
                                <td>Model</td>
                                <td>{car?.model}</td>
                            </tr>
                            <tr>
                                <td>Doors</td>
                                <td>{car?.doors}</td>
                            </tr>
                            <tr>
                                <td>Seats</td>
                                <td>{car?.seats}</td>
                            </tr>
                            <tr>
                                <td>Luggage</td>
                                <td>{car?.luggage}</td>
                            </tr>
                            <tr>
                                <td>Transmission</td>
                                <td>{car?.transmission}</td>
                            </tr>
                            {
                                car?.airConditioning && (
                                    <tr>
                                        <td>Air Conditioning</td>
                                        <td>
                                            <Check />
                                        </td>
                                    </tr>
                                )
                            }
                            <tr>
                                <td>Fuel Type</td>
                                <td>{car?.fuelType}</td>
                            </tr>
                            <tr>
                                <td>Age</td>
                                <td>{car?.age}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

export default UserReservationDetailsAccordion