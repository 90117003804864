import React, { useEffect, useState } from 'react'
import { Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { config } from '../../../config';
import DataTable from 'react-data-table-component';
import { adminUsersColumns, swalToast } from '../../../utils';
import { Loading } from '../../../components';
import { downloadUsersReport, getUsersByPage } from '../../../api';
import fileDownload from 'js-file-download';
import "./style.scss";

const { projectDetails: { adminUserPage: { title } }, routes: { admin: { admin, adminUsers } } } = config;

const AdminUsersPage = () => {
    const [loading, setLoading] = useState(true);
    const [downloading, setDownloading] = useState(false);
    const [userData, setUserData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const navigate = useNavigate();

    const loadData = async (page) => {
        try {
            const usersData = await getUsersByPage(page, perPage);
            const { content, totalElements } = usersData;
            setUserData(content);
            setTotalRows(totalElements);
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        // data table component page start from 1 but api page start from 0
        loadData(page - 1);
    };

    const handleRowClicked = (row) => {
        navigate(`/${admin}/${adminUsers}/${row.id}`)
    };

    const handlePerPageRowsChange = async (newPerPage, page) => {
        try {
            const usersData = await getUsersByPage(page - 1, newPerPage);
            const { content } = usersData;
            setUserData(content);
            setPerPage(newPerPage);
        } catch (error) {
            console.log(error)
        }
    };

    const handleDownload = async () => {
        setDownloading(true);
        try {
            const download = await downloadUsersReport();
            fileDownload(download, "users.xlsx");
            swalToast("Your download will start soon", 'success');
        } catch (error) {
            swalToast(error.response.data.message, 'error');
        } finally {
            setDownloading(false);
        }
    };

    useEffect(() => {
        loadData(0);
        // eslint-disable-next-line
    }, []);

    return (
        <div className='admin-users-page'>
            <Button
                onClick={handleDownload}
                disabled={downloading}
            >
                {downloading && <Spinner animation='border' size='sm' />}  Download User Data
            </Button>
            <div className='admin-users-table-container'>
                <DataTable
                    title={title}
                    columns={adminUsersColumns}
                    data={userData}
                    progressPending={loading}
                    progressComponent={<Loading height={500} />}
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerPageRowsChange}
                    onChangePage={handlePageChange}
                    paginationPerPage={perPage}
                    onRowClicked={handleRowClicked}
                    pagination
                    paginationServer
                    highlightOnHover
                />
            </div>
        </div>
    )
}

export default AdminUsersPage;