import React, { useState } from 'react';
import { FormTypes, SectionHeader } from "../../../";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, ButtonGroup, Form, FormCheck, InputGroup, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';
import { bookingFormInitialValues, bookingFormValidationSchema, checkDates, combineDateAndTime, swalToast, validCheck } from '../../../../utils';
import { createReservation, isVehicleAvailable } from '../../../../api';
import { config } from '../../../../config';

const { routes: { user: { userRoute, reservations } } } = config;

const BookingForm = () => {
    const [loading, setLoading] = useState(false);
    const [vehicleAvailable, setVehicleAvailable] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const { isLoggedIn } = useSelector(state => state.auth);
    const { vehicle } = useSelector(state => state.reservation);
    const navigate = useNavigate();

    const onSubmit = async (values) => {
        setLoading(true);
        const { pickUpDate, pickUpTime, dropOffDate, dropOffTime, pickUpLocation, dropOffLocation } = values;

        const dto = {
            pickUpTime: combineDateAndTime(pickUpDate, pickUpTime),
            dropOffTime: combineDateAndTime(dropOffDate, dropOffTime),
            pickUpLocation,
            dropOffLocation
        };

        try {
            await createReservation(vehicle.id, dto);
            swalToast("Reservation created successfully!", "success");
            navigate(`/${userRoute}/${reservations}`)
        } catch (error) {
            console.log(error);
            swalToast(error.response.data.message, "error")
        } finally {
            setLoading(false);
        }
    };

    const formik = useFormik({
        initialValues: bookingFormInitialValues,
        validationSchema: bookingFormValidationSchema,
        onSubmit
    });

    const handleAvailability = async () => {
        setLoading(true);
        const { pickUpDate, pickUpTime, dropOffDate, dropOffTime } = formik.values;

        const dto = {
            carId: vehicle.id,
            pickUpDateTime: combineDateAndTime(pickUpDate, pickUpTime),
            dropOffDateTime: combineDateAndTime(dropOffDate, dropOffTime)
        }

        try {
            if (!checkDates(formik.values)) return swalToast("Drop-off date should be at least 1 hour after from pick-up date", "error");
            const data = await isVehicleAvailable(dto);
            const { available, totalPrice } = data;
            setTotalPrice(totalPrice);
            setVehicleAvailable(available);
            if (!available) return swalToast("The car you selected is not available in that day. Please select another date.", "error");
        } catch (error) {
            console.log(error);
            swalToast(error.response.data.message, "error")
        } finally {
            setLoading(false);
        }
    }

    console.log(vehicleAvailable)

    return (
        <>
            <SectionHeader title1="Book" title2="Now" />
            {
                isLoggedIn
                ||
                <Alert> Please login first to check if the car is available</Alert>
            }
            <Form noValidate onSubmit={formik.handleSubmit}>
                <fieldset disabled={!isLoggedIn}>
                    <FormTypes
                        formik={formik}
                        name="pickUpLocation"
                        floating={true}
                        label="Pick Up Location"
                    />
                    <FormTypes
                        formik={formik}
                        name="dropOffLocation"
                        floating={true}
                        label="Drop Off Location"
                    />

                    <InputGroup className='mb-3'>
                        <FormTypes
                            formik={formik}
                            name="pickUpDate"
                            type="date"
                            floating={true}
                            label="Pick Up Date"
                        />
                        <FormTypes
                            formik={formik}
                            name="pickUpTime"
                            type="time"
                            floating={true}
                            label="Pick Up Time"
                        />
                    </InputGroup>

                    <InputGroup className='mb-3'>
                        <FormTypes
                            formik={formik}
                            name="dropOffDate"
                            type="date"
                            floating={true}
                            label="Drop Off Date"
                        />
                        <FormTypes
                            formik={formik}
                            name="dropOffTime"
                            type="time"
                            floating={true}
                            label="Drop Off Time"
                        />
                    </InputGroup>
                    <Button
                        variant="secondary"
                        className={`w-100 ${vehicleAvailable || "d-none"}`}
                        onClick={handleAvailability}
                        disabled={loading}
                    >
                        {loading && <Spinner animation='border' size='sm' />} Check If Available
                    </Button>
                </fieldset>
                <fieldset className={`mt-5 ${(vehicleAvailable && isLoggedIn) || "d-none"}`}
                    disabled={!(vehicleAvailable && isLoggedIn)}
                >
                    <Alert variant='success'>
                        <h2>Total Price: ${totalPrice}</h2>
                        <FormTypes
                            formik={formik}
                            name="cardNo"
                            label="Card Number"
                            floating={true}
                            asForm="ReactInputMask"
                            mask="9999-9999-9999-9999"
                        />
                        <FormTypes
                            formik={formik}
                            name="cardHolderName"
                            label="Card Holder Name"
                            floating={true}
                        />
                        <FormTypes
                            formik={formik}
                            name="expiryDate"
                            label="Expiry Date"
                            floating={true}
                            type="date"
                        />
                        <FormTypes
                            formik={formik}
                            name="cvv"
                            label="CVV"
                            floating={true}
                            asForm="ReactInputMask"
                            mask="999"
                        />

                        <FormCheck
                            type="checkbox"
                            id='terms'
                            label="I agree to the terms and conditions"
                            {...formik.getFieldProps("terms")}
                            {...validCheck("terms", formik)}
                        />

                        <ButtonGroup className='mt-3 w-100'>
                            <Button
                                variant="warning"
                                disabled={loading}
                                onClick={() => setVehicleAvailable(false)}
                                className='w-50 text-primary'
                            >
                                Edit
                            </Button>
                            <Button
                                type='submit'
                                disabled={loading}
                                className="w-50"
                            >
                                Book Now
                            </Button>
                        </ButtonGroup>
                    </Alert>
                </fieldset>
            </Form>
        </>
    )
}

export default BookingForm