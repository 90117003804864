import React, { useEffect, useState } from 'react';
import { getVehiclesByPage } from '../../../api';
import { SectionHeader, Spacer, VehicleCard } from "../../";
import { Col, Container, Pagination, Row } from 'react-bootstrap';

const Vehicles = () => {
    const [loading, setLoading] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const [paging, setPaging] = useState({});

    const loadData = async (page) => {
        try {
            const data = await getVehiclesByPage(page);
            const { content, totalPages, pageable: { pageNumber } } = data;
            setVehicles(content);
            setPaging({ totalPages, pageNumber });
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadData(0);
    }, []);

    return (
        <Container className='vehicles'>
            <SectionHeader
                title1="Vehicle"
                title2="Models"
                desc="To contribute to positive change and achieve our sustainability goals with many extraordinary"
            />
            <Spacer />
            {
                loading
                    ? <div>Loading...</div>
                    : (
                        <>
                            <Row className='gy-4'>
                                {
                                    vehicles.length > 0
                                    && vehicles?.map((vehicle) => (
                                        <Col key={vehicle?.id} md={6} lg={4}>
                                            <VehicleCard {...vehicle} />
                                        </Col>
                                    ))
                                }
                            </Row>
                            {
                                paging?.totalPages > 1
                                && (
                                    <Row className='mt-5'>
                                        <Pagination className='justify-content-center'>
                                            <Pagination.First
                                                onClick={() => loadData(0)}
                                                disabled={paging?.pageNumber === 0}
                                            />
                                            <Pagination.Prev
                                                onClick={() => loadData(paging?.pageNumber - 1)}
                                                disabled={paging?.pageNumber <= 0}
                                            />
                                            {
                                                [...Array(paging?.totalPages)].map((item, index) => (
                                                    <Pagination.Item
                                                        active={index === paging?.pageNumber}
                                                        key={index}
                                                        onClick={() => index !== paging?.pageNumber && loadData(index)}
                                                    >
                                                        {index + 1}
                                                    </Pagination.Item>
                                                ))
                                            }
                                            <Pagination.Next
                                                onClick={() => loadData(paging?.pageNumber + 1)}
                                                disabled={paging?.pageNumber >= paging?.totalPages - 1}
                                            />
                                            <Pagination.Last
                                                onClick={() => loadData(paging?.totalPages - 1)}
                                                disabled={paging?.pageNumber >= paging?.totalPages - 1}
                                            />

                                        </Pagination>
                                    </Row>
                                )
                            }
                        </>
                    )
            }
        </Container>
    )
};

export default Vehicles;