import React from 'react'
import { BestOffers, PopularVehicles, Slider, Spacer, Team, WhatWeDo } from '../../../components'

const HomePage = () => {
    return (
        <div>
            <Slider />
            <Spacer />
            <PopularVehicles />
            <Spacer />
            <BestOffers />
            <Spacer />
            <Team />
            <Spacer />
            <WhatWeDo />
        </div>
    )
}

export default HomePage