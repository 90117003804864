import React from 'react'
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'
import { AboutPage, AdminContactMessagesPage, AdminDashboardPage, AdminReservationsPage, AdminUsersPage, AdminVehiclesPage, ContactPage, HomePage, LoginPage, PrivacyPolicyPage, RegisterPage, UserProfilePage, UserReservationDetailsPage, UserReservationsPage, VehicleDetailsPage, VehiclesPage, ErrorPage, AdminUserDetailsPage, AdminVehicleDetailsPage, AdminReservationDetailsPage, AdminContactMessageDetailsPage, AdminNewVehiclePage } from '../pages'
import { config } from '../config'
import { AuthLayout, VisitorLayout, UserLayout, AdminLayout } from '../layouts'

const AppRouter = () => {
    const { routes: { about, admin: { admin, contactMessages, dashboard, adminReservations, adminUsers, adminVehicles }, contact, home, login, privacyPolicy, register, user: { userRoute, profile, reservations }, vehicles, errors: { notFoundRoute, forbiddenRoute } }, projectDetails: { errors: { notFound, forbidden } } } = config;

    return (
        <Router>
            <Routes>
                <Route path={home}>
                    <Route element={<VisitorLayout />}>
                        <Route index element={<HomePage />} />
                        <Route path={about} element={<AboutPage />} />
                        <Route path={contact} element={<ContactPage />} />
                        <Route path={privacyPolicy} element={<PrivacyPolicyPage />} />
                        <Route path={vehicles}>
                            <Route index element={<VehiclesPage />} />
                            <Route path=":vehicleId" element={<VehicleDetailsPage />} />
                        </Route>

                        <Route element={<UserLayout />}>
                            <Route path={userRoute}>
                                <Route index element={<UserProfilePage />} />
                                <Route path={profile} element={<UserProfilePage />} />
                                <Route path={reservations}>
                                    <Route index element={<UserReservationsPage />} />
                                    <Route path=":reservationId" element={<UserReservationDetailsPage />} />
                                </Route>
                            </Route>
                        </Route>
                        <Route path={notFoundRoute} element={<ErrorPage {...notFound} />} />
                        <Route path={forbiddenRoute} element={<ErrorPage {...forbidden} />} />
                    </Route>

                    <Route element={<AuthLayout />}>
                        <Route path={login} element={<LoginPage />} />
                        <Route path={register} element={<RegisterPage />} />
                    </Route>

                    <Route element={<AdminLayout />}>
                        <Route path={admin}>
                            <Route index element={<AdminDashboardPage />} />
                            <Route path={dashboard} element={<AdminDashboardPage />} />
                            <Route path={adminUsers}>
                                <Route index element={<AdminUsersPage />} />
                                <Route path=":userId" element={<AdminUserDetailsPage />} />
                            </Route>
                            <Route path={adminVehicles}>
                                <Route index element={<AdminVehiclesPage />} />
                                <Route path="new" element={<AdminNewVehiclePage />} />
                                <Route path=":vehicleId" element={<AdminVehicleDetailsPage />} />
                            </Route>
                            <Route path={adminReservations}>
                                <Route index element={<AdminReservationsPage />} />
                                <Route path=":reservationId" element={<AdminReservationDetailsPage />} />
                            </Route>
                            <Route path={contactMessages}>
                                <Route index element={<AdminContactMessagesPage />} />
                                <Route path=":contactMessageId" element={<AdminContactMessageDetailsPage />} />
                            </Route>
                        </Route>
                    </Route>
                </Route>
            </Routes>
        </Router>
    )
}

export default AppRouter