import React, { useEffect, useState } from 'react';
import { BookingForm, DetailsPanel, PageHeader, Spacer } from '../../../components';
import { config } from '../../../config';
import { Col, Container, Row } from 'react-bootstrap';
import { getVehicleById } from '../../../api';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setVehicle } from '../../../store';
import "./style.scss";

const { projectDetails: { vehicleDetails: { title } } } = config;

const VehicleDetailsPage = () => {
    const [loading, setLoading] = useState(false);
    const { vehicleId } = useParams();
    const dispatch = useDispatch();

    const loadData = async () => {
        try {
            const data = await getVehicleById(vehicleId);
            dispatch(setVehicle(data))
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <PageHeader title={title} />
            <Spacer />
            <Container className='vehicle-details'>
                {
                    loading
                        ? "Loading..."
                        : <Row>
                            <Col lg={8}>
                                <DetailsPanel />
                            </Col>
                            <Col lg={4}>
                                <BookingForm />
                            </Col>
                        </Row>
                }
            </Container>
            <Spacer />
        </>
    )
}

export default VehicleDetailsPage