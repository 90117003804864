import React from 'react';
import "./style.scss";

const ErrorPage = ({ code, title, desc }) => {
    return (
        <div className='error-page'>
            <div className='error-code'>{code}</div>
            <h2>{title}</h2>
            <p>{desc}</p>
        </div>
    )
}

export default ErrorPage;