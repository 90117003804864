import React from 'react'
import { config } from '../../../../config';
import { SectionHeader, Spacer, TeamMember } from "../../../";
import { Container, Row, Col } from "react-bootstrap";

const Team = () => {
    const { projectDetails: { about: { team: { members, title1, title2, desc } } } } = config;

    return (
        <div className='team'>
            <SectionHeader title1={title1} title2={title2} desc={desc} />
            <Spacer />
            <Container>
                <Row className='gy-5'>
                    {
                        members.map((member) => (
                            <Col lg={4} key={member.id}>
                                <TeamMember {...member} />
                            </Col>
                        ))
                    }
                </Row>
            </Container>
        </div>
    )
}

export default Team;