import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Logo } from '../../../assets/images';
import { config } from '../../../config';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BookOnline, Dashboard, DirectionsCar, Logout, Message, PeopleAlt, Web } from '@mui/icons-material';
import { swalQuestion } from '../../../utils';
import { useDispatch } from 'react-redux';
import { logout } from "../../../store";
import { encryptedLocalStorage } from '../../../api';
import "./style.scss";


const { projectDetails: { adminPanel: { title, listItems: { dashboard, panelUsers, vehicles, reservations, panelContactMessages, website, panelLogout } } }, routes: { home, admin: { admin, adminReservations, adminVehicles, contactMessages, adminUsers } } } = config;

const AdminSidebar = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        swalQuestion("Logout", "Are you sure you want to logout?")
            .then((result) => {
                if (result.isConfirmed) {
                    console.log("Logout");
                    dispatch(logout());
                    encryptedLocalStorage.removeItem("token");
                    navigate(`${home}`);
                }
            })
    };

    return (
        <Navbar
            bg='warning'
            expand='lg'
            className='admin-sidebar'
            variant='dark'
        >
            <Container>
                <Navbar.Brand>
                    <img src={Logo} alt={title} title={title} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="admin-panel" />
                <Navbar.Collapse id="admin-panel">
                    <Nav className="mt-5">
                        <Nav.Link
                            as={Link}
                            to={`/${admin}`}
                            active={pathname === `/${admin}`}
                        >
                            <Dashboard /> {dashboard}
                        </Nav.Link>
                        <Nav.Link
                            as={Link}
                            to={`${admin}/${adminUsers}`}
                            active={pathname.startsWith(`/${admin}/${adminUsers}`)}
                        >
                            <PeopleAlt /> {panelUsers}
                        </Nav.Link>
                        <Nav.Link
                            as={Link}
                            to={`${admin}/${adminVehicles}`}
                            active={pathname.startsWith(`/${admin}/${adminVehicles}`)}
                        >
                            <DirectionsCar /> {vehicles}
                        </Nav.Link>
                        <Nav.Link
                            as={Link}
                            to={`${admin}/${adminReservations}`}
                            active={pathname.startsWith(`/${admin}/${adminReservations}`)}
                        >
                            <BookOnline /> {reservations}
                        </Nav.Link>
                        <Nav.Link
                            as={Link}
                            to={`${admin}/${contactMessages}`}
                            active={pathname.startsWith(`/${admin}/${contactMessages}`)}
                        >
                            <Message /> {panelContactMessages}
                        </Nav.Link>
                        <Nav.Link
                            as={Link}
                            to={`${home}`}
                        >
                            <Web /> {website}
                        </Nav.Link>
                        <Nav.Link
                            onClick={handleLogout}
                        >
                            <Logout /> {panelLogout}
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
};

export default AdminSidebar;