import React, { useState } from 'react';
import { config } from '../../../../config';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { adminNewVehicleFormInitialValues, adminVehicleFormValidationSchema, swalToast } from '../../../../utils';
import { FormTypes, Loading } from '../../../../components';
import { Badge, Button, ButtonGroup, Col, Form, Row, Spinner } from 'react-bootstrap';
import "./style.scss";
import { addVehicle, uploadVehicleImage } from '../../../../api';

const { projectDetails: { adminVehicleDetails: { transmissionTypes, airConditioningTypes, fuelTypes } }, routes: { admin: { admin, adminVehicles } } } = config;

const AdminNewVehiclePage = () => {
    const [loading, setLoading] = useState(false);
    const [imageSrc, setImageSrc] = useState("");
    const navigate = useNavigate();

    const onSubmit = async (values) => {
        setLoading(true);
        // to upload a file to an API, we create a FormData and place the image inside
        // if there are any, other information can be included as well
        const formData = new FormData();
        formData.append("file", values.image);
        try {
            const imageData = await uploadVehicleImage(formData);
            const imageId = imageData.imageId;
            delete values.image;

            await addVehicle(imageId, values);
            swalToast("Vehicle added successfully!", "success");
            navigate(`/${admin}/${adminVehicles}`)
        } catch (error) {
            swalToast(error.response.data.message, "error");
        } finally {
            setLoading(false);
        }
    };

    const formik = useFormik({
        initialValues: adminNewVehicleFormInitialValues,
        validationSchema: adminVehicleFormValidationSchema,
        onSubmit
    });

    const handleChangeImage = (e) => {
        const file = e.target.files[0];

        if (!file) return;
        formik.setFieldValue("image", file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setImageSrc(reader.result);
        };
    };

    return loading
        ? <Loading height={500} />
        : (
            <Form
                noValidate
                onSubmit={formik.handleSubmit}
            >
                <div className='admin-new-vehicle-form'>
                    <Row className='mb-5 gy-5'>
                        <Col xl={3} className='image-area'>
                            {
                                imageSrc
                                && <img
                                    src={imageSrc}
                                    alt={formik?.values?.model}
                                    title={formik?.values?.model}
                                />
                            }
                            <Form.Group controlId='fileSelect'>
                                <Form.Control
                                    type='file'
                                    name='image'
                                    accept=".jpg,.jpeg,.png"
                                    onChange={handleChangeImage}
                                    className='d-none'
                                />
                                <div className='cover'>
                                    <Button as={Form.Label}>
                                        Select Image
                                    </Button>
                                </div>
                            </Form.Group>
                            <Badge bg='danger' className='image-error'>
                                {formik?.errors?.image}
                            </Badge>
                        </Col>
                        <Col xl={9}>
                            <Row className='row-cols-1 row-cols-md2 row-cols-lg-3'>
                                <FormTypes
                                    formik={formik}
                                    label="Model"
                                    name="model"
                                    asGroup={Col}
                                />
                                <FormTypes
                                    formik={formik}
                                    label='Doors'
                                    name='doors'
                                    type='number'
                                    asGroup={Col}
                                />
                                <FormTypes
                                    formik={formik}
                                    label='Seats'
                                    name='seats'
                                    type='number'
                                    asGroup={Col}
                                />
                                <FormTypes
                                    formik={formik}
                                    label='Luggage'
                                    name='luggage'
                                    type='number'
                                    asGroup={Col}
                                />
                                <FormTypes
                                    formik={formik}
                                    label="Age"
                                    name="age"
                                    type="number"
                                    asGroup={Col}
                                />
                                <FormTypes
                                    formik={formik}
                                    label="Price Per Hour"
                                    name="pricePerHour"
                                    type="number"
                                    asGroup={Col}
                                />
                                <FormTypes
                                    formik={formik}
                                    label="Transmission"
                                    name="transmission"
                                    type="select"
                                    asGroup={Col}
                                    itemsArr={transmissionTypes}
                                />
                                <FormTypes
                                    formik={formik}
                                    label="Air Conditioning"
                                    name="airConditioning"
                                    type="select"
                                    asGroup={Col}
                                    itemsArr={airConditioningTypes}
                                />
                                <FormTypes
                                    formik={formik}
                                    label="Fuel Type"
                                    name="fuelType"
                                    type="select"
                                    asGroup={Col}
                                    itemsArr={fuelTypes}
                                />
                            </Row>
                        </Col>
                    </Row>
                    <div className="text-end">
                        <ButtonGroup>
                            <Button
                                onClick={() => navigate(`/${admin}/${adminVehicles}`)}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant='warning'
                                type='submit'
                                disabled={!(formik.dirty && formik.isValid) || loading}
                            >
                                {loading && <Spinner animation='border' size='sm' />}  Create
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>
            </Form>
        )
};

export default AdminNewVehiclePage;