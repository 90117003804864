import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { AdminCarousel, DashboardCard, Loading } from '../../../components';
import { config } from '../../../config';
import { getReservationsByPage, getUsersByPage, getVehiclesByPage } from '../../../api';
import { BookOnline, DirectionsCar, PeopleAlt } from '@mui/icons-material';

const { routes: { admin: { admin, adminUsers, adminVehicles, adminReservations } } } = config;

const AdminDashboardPage = () => {
    const [loading, setLoading] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const [reservations, setReservations] = useState([]);
    const [members, setMembers] = useState([]);

    const loadData = async (page) => {
        try {
            const userData = await getUsersByPage(page, 100);
            const reservationData = await getReservationsByPage(page, 100);
            const vehicleData = await getVehiclesByPage(page, 100);
            setVehicles(vehicleData);
            setReservations(reservationData);
            setMembers(userData);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadData(0);
    }, []);

    return (
        <Container className='admin-dashboard'>
            {
                loading
                    ? <Loading height={500} />
                    : <>
                        <Row>
                            <Col lg={4}>
                                <DashboardCard
                                    statistics={members?.totalElements}
                                    title="Members"
                                    icon={<PeopleAlt />}
                                    path={`/${admin}/${adminUsers}`}
                                />
                            </Col>
                            <Col lg={4}>
                                <DashboardCard
                                    statistics={vehicles?.totalElements}
                                    title="Vehicles"
                                    icon={<DirectionsCar />}
                                    path={`/${admin}/${adminVehicles}`}
                                />
                            </Col>
                            <Col lg={4}>
                                <DashboardCard
                                    statistics={reservations?.totalElements}
                                    title="Reservations"
                                    icon={<BookOnline />}
                                    path={`/${admin}/${adminReservations}`}
                                />
                            </Col>
                        </Row>
                        <AdminCarousel vehicles={vehicles} />
                    </>
            }
        </Container>
    )
}

export default AdminDashboardPage