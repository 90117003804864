import React from 'react';
import { PageHeader, Spacer, UserReservations } from '../../../components';
import { config } from '../../../config';

const { projectDetails: { userReservations: { title } } } = config;

const UserReservationsPage = () => {
    return (
        <>
            <PageHeader title={title} />
            <Spacer />
            <UserReservations />
            <Spacer />
        </>
    )
}

export default UserReservationsPage;