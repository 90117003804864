import React, { useEffect, useState } from 'react';
import { Container, Pagination, Row } from 'react-bootstrap';
import UserReservationsTable from './reservations-table';
import { getReservationsByPage } from '../../../api';

const UserReservations = () => {
    const [loading, setLoading] = useState(true);
    const [reservations, setReservations] = useState([]);
    const [paging, setPaging] = useState({});

    const loadData = async (page) => {
        try {
            const data = await getReservationsByPage(page);
            const { content, totalPages, pageable: { pageNumber } } = data;
            setReservations(content);
            setPaging({ totalPages, pageNumber });
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
       loadData(0);
    }, []);

    return (
        <Container>
            <UserReservationsTable loading={loading} reservations={reservations} />
            {
                paging.totalPages > 1 && (
                    <Row className='mt-5'>
                        <Pagination className='justify-content-center'>
                            <Pagination.First
                                onClick={() => loadData(0)}
                                disabled={paging.pageNumber <= 0}
                            />
                            <Pagination.Prev
                                onClick={() => loadData(paging.pageNumber - 1)}
                                disabled={paging.pageNumber <= 0}
                            />
                            {
                                [...Array(paging.totalPages)].map((page, index) => (
                                    <Pagination.Item
                                        active={index === paging.pageNumber}
                                        key={index}
                                        onClick={() => index !== paging.pageNumber && loadData(index)}
                                    >
                                        {index + 1}
                                    </Pagination.Item>
                                ))
                            }
                            <Pagination.Next
                                onClick={() => loadData(paging.pageNumber + 1)}
                                disabled={paging.pageNumber >= paging.totalPages - 1}
                            />
                            <Pagination.Last
                                onClick={() => loadData(paging.totalPages - 1)}
                                disabled={paging.pageNumber >= paging.totalPages - 1}
                            />
                        </Pagination>
                    </Row>
                )
            }
        </Container>
    )
};

export default UserReservations;