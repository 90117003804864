import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { config } from '../../../config';
import { useFormik } from 'formik';
import { loginFormInitialValues, loginFormValidationSchema, swalToast } from '../../../utils';
import { Button, Form, Spinner } from "react-bootstrap";
import { encryptedLocalStorage, getUser, login } from '../../../api';
import { loginFailure, loginSuccess } from '../../../store';
import { FormTypes, PasswordInput } from '../../../components';
import "./style.scss";

const LoginPage = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { routes: { register, home } } = config;

    const onSubmit = async (values) => {
        setLoading(true);
        try {
            const data = await login(values);
            encryptedLocalStorage.setItem("token", data.token);
            const respUser = await getUser();
            dispatch(loginSuccess(respUser))
            swalToast("You have successfully logged in!", "success");
            navigate(home);
        } catch (error) {
            dispatch(loginFailure());
            swalToast(error.response.data.message, 'error')
        } finally {
            setLoading(false);
        }
    };

    const formik = useFormik({
        initialValues: loginFormInitialValues,
        validationSchema: loginFormValidationSchema,
        onSubmit
    });

    return (
        <Form noValidate onSubmit={formik.handleSubmit} className='login-form'>
            <FormTypes
                formik={formik}
                label='Email Address'
                name="email"
                type='email'
                placeholder="Enter your email"
            />
            <PasswordInput
                formik={formik}
                label='Password'
                name='password'
                placeholder='Enter your password'
            />

            <Button
                variant="primary"
                type="submit"
                disabled={!(formik.dirty && formik.isValid) || loading}
                className='mb-3'
            >
                {loading && <Spinner animation='border' size='sm' />} login
            </Button>
            <p>OR</p>
            <Button
                variant='primary'
                onClick={() => navigate(`/${register}`)}
                disabled={loading}
            >
                {loading && <Spinner animation='border' size='sm' />} register
            </Button>
        </Form>
    )
}

export default LoginPage