import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    vehicle: null,
    reservations: null,
};

export const reservationSlice = createSlice({
    name: "reservation",
    initialState,
    reducers: {
        setVehicle: (state, action) => {
            state.vehicle = action.payload;
        },
        setReservation: (state, action) => {
            state.reservations = action.payload;
        },
    },
});

export const { setVehicle, setReservation } = reservationSlice.actions;

export default reservationSlice.reducer;