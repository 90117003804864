import React from 'react'
import { Badge, Card } from 'react-bootstrap';
import { Spacer } from "../../../";
import { config } from '../../../../config';
import { useSelector } from 'react-redux';
import { DirectionsCar, SensorDoor, AirlineSeatReclineNormal, RvHookup, SportsGolf, AcUnit, LocalGasStation, Filter1 } from "@mui/icons-material";

const { API_URL, endpoints: { files: { display } } } = config;

const DetailsPanel = () => {
    const vehicle = useSelector(state => state.reservation.vehicle);

    return (
        <>
            <div className='panel-title'>
                <h1>{vehicle?.model}</h1>
                <h3>
                    <Badge>$ {vehicle?.pricePerHour}/hour</Badge>
                </h3>
            </div>
            <Card>
                <img
                    src={`${API_URL + display}/${vehicle?.image}`}
                    alt={vehicle?.model}
                    title={vehicle?.model}
                />
            </Card>
            <Spacer height={30} />
            <h2>Property Highlights</h2>
            <ul className='mb-5'>
                <li> <DirectionsCar /> Model: {vehicle?.model} </li>
                <li> <SensorDoor /> Doors: {vehicle?.doors}</li>
                <li> <AirlineSeatReclineNormal /> Seats: {vehicle?.seats} </li>
                <li> <RvHookup /> Luggage: {vehicle?.luggage} </li>
                <li> <SportsGolf /> Transmission: {vehicle?.transmisson} </li>
                {
                    vehicle?.airConditioning
                    && <li> <AcUnit />  Air Conditioning </li>
                }
                <li> <LocalGasStation /> Fuel Type: {vehicle?.fuelType} </li>
                <li> <Filter1 /> Age: {vehicle?.age} </li>
            </ul>
        </>
    )
}

export default DetailsPanel