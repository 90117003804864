import React from 'react'
import { config } from '../../../../config';
import { OfferItem, SectionHeader } from "../../../";
import { Col, Container, Row } from 'react-bootstrap';
import { DirectionsCar } from "@mui/icons-material";
import { ImgBestOffer } from '../../../../assets/images';
import './style.scss';

const BestOffers = () => {
    const { projectDetails: { about: { bestOffers: { offers, title } } } } = config;

    return (
        <div className='best-offers'>
            <SectionHeader title1="Get Our" title2="Best Offers" />
            <div className='offers'>
                <Container>
                    <Row>
                        <Col lg={4}>
                            <OfferItem {...offers[0]} icon={<DirectionsCar />} />
                            <OfferItem {...offers[1]} icon={<DirectionsCar />} />
                            <OfferItem {...offers[2]} icon={<DirectionsCar />} />
                        </Col>
                        <Col lg={4}>
                            <img
                                src={ImgBestOffer}
                                alt={title}
                                title={title}
                                className='img-fluid'
                            />
                        </Col>
                        <Col lg={4}>
                            <OfferItem {...offers[3]} icon={<DirectionsCar />} />
                            <OfferItem {...offers[4]} icon={<DirectionsCar />} />
                            <OfferItem {...offers[5]} icon={<DirectionsCar />} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default BestOffers