import React, { useState } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import { FormTypes } from '../../';
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import { swalToast, userProfileFormValidationSchema } from '../../../utils'
import { updateUser } from '../../../api'

const UserProfileForm = () => {
    const [loading, setLoading] = useState(false);
    const { user } = useSelector(state => state.auth);
    const { firstName, lastName, email, phoneNumber, address, zipCode, builtIn } = user;

    const initialValues = {
        firstName,
        lastName,
        email,
        phoneNumber,
        address,
        zipCode
    };

    const onSubmit = async (values) => {
        setLoading(true);
        try {
            await updateUser(values);
            swalToast('Profile updated successfully!', 'success')
        } catch (error) {
            swalToast(error.response.data.message, 'error');
        } finally {
            setLoading(false);
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema: userProfileFormValidationSchema,
        onSubmit
    });

    return (
        <Form noValidate onSubmit={formik.handleSubmit}>
            <fieldset disabled={builtIn}>
                <FormTypes
                    formik={formik}
                    name="firstName"
                    label="First Name"
                />
                <FormTypes
                    formik={formik}
                    name="lastName"
                    label="Last Name"
                />
                <FormTypes
                    formik={formik}
                    name="email"
                    label="Email"
                    type="email"
                />
                <FormTypes
                    formik={formik}
                    name="phoneNumber"
                    label="Phone Number"
                    asForm="ReactInputMask"
                    mask="(999) 999-9999"
                />
                <FormTypes
                    formik={formik}
                    name="address"
                    label="Address"
                />
                <FormTypes
                    formik={formik}
                    name="zipCode"
                    label="Zip Code"
                    type="number"
                />
                <Button
                    type='submit'
                    disabled={!(formik.isValid && formik.dirty) || loading}
                    className='text-uppercase w-100 mt-3'
                >
                    {loading && <Spinner animation='border' size='sm' />}  Update information
                </Button>

            </fieldset>
        </Form>
    )
}

export default UserProfileForm