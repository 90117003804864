import React, { useEffect, useState } from 'react'
import { config } from '../../../config';
import { useNavigate } from 'react-router-dom';
import DataTable from "react-data-table-component";
import { Loading } from '../../../components';
import { adminContactMessagesColumns } from '../../../utils';
import { getMessagesByPage } from '../../../api';
import "./style.scss";

const { projectDetails: { adminContactMessagesPage: { title } }, routes: { admin: { admin, contactMessages } } } = config;

const AdminContactMessagesPage = () => {
    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const navigate = useNavigate();

    const handlePerPageRowsChange = async (newPerPage, page) => {
        try {
            const messagesData = await getMessagesByPage(page - 1, newPerPage);
            const { content } = messagesData;
            setMessages(content);
            setPerPage(newPerPage);
        } catch (error) {
            console.log(error)
        }
    };

    const handlePageChange = (page) => {
        loadData(page - 1);
    };

    const handleRowClick = (row) => {
        navigate(`/${admin}/${contactMessages}/${row.id}`)
    };

    const loadData = async (page) => {
        try {
            const messageData = await getMessagesByPage(page, perPage);
            const { content, totalElements } = messageData;
            setMessages(content);
            setTotalRows(totalElements);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadData(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='admin-contact-messages-page'>
            <div className="admin-contact-messages-table-container">
                <DataTable
                    title={title}
                    columns={adminContactMessagesColumns}
                    data={messages}
                    progressPending={loading}
                    progressComponent={<Loading height={500} />}
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerPageRowsChange}
                    paginationPerPage={perPage}
                    onChangePage={handlePageChange}
                    onRowClicked={handleRowClick}
                    pagination
                    paginationServer
                    highlightOnHover
                />
            </div>
        </div>
    )
}

export default AdminContactMessagesPage;