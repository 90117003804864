export const config = {
    projectDetails: {
        name: 'Velocity Rent',
        description: 'Experience the Road in Style and Comfort! Choose from our diverse fleet of affordable and luxury vehicles for a seamless, hassle-free car rental experience. Discover unbeatable deals and personalized service at Velocity Rent - your trusted car rental partner.',
        version: '1.0.0',
        phone: "+1 (555) 123-4567",
        phone2: "+1 (555) 123-6745",
        email: "info@velocityrent.com",
        address: "1234 Main St, New York, NY 10001",
        website: "https://velocityrent.com",
        socialMedia: {
            twitter: "https://twitter.com",
            facebook: "https://facebook.com",
            instagram: "https://instagram.com",
            linkedin: "https://linkedin.com",
            youtube: "https://youtube.com",
        },
        about: {
            title: "About Us",
            description: "Velocity Rent is a leading car rental company in the New York area. We offer a wide range of vehicles for rent, from economy cars to luxury sedans. Our goal is to provide our customers with the best service possible at an affordable price. We have been in business since 1999 and have grown to become one of the largest car rental companies in the United States. Our fleet includes over 500 vehicles, including vans, trucks, SUVs, and more! We also offer 24-hour roadside assistance so you can rest assured that we will be there when you need us most. If you're looking for a reliable car rental company with competitive rates and excellent customer service, look no further than Velocity Rent!",
            bestOffers: {
                title: "Best Offers",
                offers: [
                    {
                        title: "Featured Luxury Cars",
                        desc: "Discover the ultimate driving experience with our collection of Featured Luxury Cars. These premium vehicles offer superior comfort, top-of-the-line amenities, and exceptional performance to elevate your journey. From sleek sports cars to spacious luxury SUVs, we have the perfect luxury vehicle to match your style and preferences. Whether you're heading for a business trip or a grand vacation, turn heads and travel in style with our exclusive selection.",
                        direction: ""
                    },
                    {
                        title: "Insurance Coverage",
                        desc: "Your peace of mind is our priority. Our comprehensive Insurance Coverage ensures that you're protected throughout your rental period. From collision damage waiver to personal accident insurance, we have various options to suit your needs. We understand the uncertainties of the road, hence we've designed our insurance policies to safeguard you against unforeseen incidents, providing you with confidence and reassurance every mile of your journey.",
                        direction: ""
                    },
                    {
                        title: "24/7 Roadside Assistance",
                        desc: "Never feel stranded with our 24/7 Roadside Assistance. No matter where you are or what time it is, we're just a call away. Our dedicated team is ready to help with anything from a flat tire, engine troubles to lockouts, ensuring that your journey continues with minimal disruption. With our support, you can travel with the assurance that you're never alone on the road.",
                        direction: ""
                    },
                    {
                        title: "Any Locations Rent",
                        desc: "Experience the freedom to explore with our Any Locations Rent service. Whether you're landing in a bustling city, heading to a serene beach, or exploring the countryside, we've got you covered. Our extensive network of branches across the country ensures that your perfect rental vehicle is always within reach. Simply choose your pickup and drop-off locations, and we'll handle the rest.",
                        direction: "right"
                    },
                    {
                        title: "12.000+ cars",
                        desc: "Choose from our expansive fleet of over 12,000 cars, catering to every need and desire. Compact city cars, rugged SUVs, comfortable family sedans, or luxury sports cars - we have it all. Each vehicle in our diverse selection is well-maintained and ready to take you where you need to go. With such a wide variety, you're sure to find a car that fits your journey perfectly.",
                        direction: "right"
                    },
                    {
                        title: "Cleaning & Sanitization",
                        desc: "Your health and safety are of utmost importance to us. We adhere to strict Cleaning & Sanitization protocols to ensure every vehicle is thoroughly cleaned and disinfected before each rental. From high-touch areas like steering wheels and door handles to seats and controls, we leave no spot untouched. Enjoy a worry-free rental experience, knowing that your vehicle provides a clean and safe environment for your journey.",
                        direction: "right"
                    }
                ],
            },
            team: {
                title: "Our Team",
                members: [
                    {
                        id: 1,
                        name: "James Patterson",
                        title: "Fleet Manager",
                        desc: "With over a decade of experience in the automotive industry, he ensures that our fleet of over 12,000 vehicles remains in top condition, ready for the road. James's meticulous attention to detail and extensive knowledge of various car models make him instrumental in managing our diverse fleet",
                        image: "team1.jpg"
                    },
                    {
                        id: 2,
                        name: "Linda Chavez",
                        title: "Customer Service Manager",
                        desc: "With her superb communication skills and deep understanding of customer needs, she leads a team dedicated to providing exceptional service to our clients. Linda's commitment to customer satisfaction has been crucial in establishing our reputation as a trusted car rental provider.",
                        image: "team2.jpg"
                    },
                    {
                        id: 3,
                        name: "Michael Johnson",
                        title: "IT Specialist",
                        desc: "Michael is the backbone of our seamless online booking system. He constantly works on optimizing our website and mobile app to ensure a user-friendly and secure booking experience for our clients. Michael's expertise in IT helps us stay at the forefront of digital innovation in the car rental industry.",
                        image: "team3.jpg"
                    },
                ]
            },
            whoWeAre: {
                title: "Who We Are",
                desc: "Founded in 2003, Velocity Rent is a premier car rental service with a nationwide presence. With over two decades of experience in the car rental industry, we have established ourselves as a trusted provider of exceptional and personalized experiences for all our customers. Our vision is rooted in innovation, customer satisfaction, and service excellence. Over the years, we have grown substantially, always striving to improve and expand our services to meet our customers' evolving needs. Our dedicated team ensures the smooth functioning of our vast array of services, from seamless online bookings to efficient car returns. Our commitment to operational efficiency is central to our reputation for providing hassle-free car rental experiences. We proudly manage an expansive fleet of over 12,000 vehicles, encompassing a wide range of models to suit diverse customer preferences. Each vehicle is meticulously managed and maintained, ensuring it remains in top condition and ready for the road. Financial sustainability and profitability are at the heart of our business model. Through strategic financial planning and rigorous cost management, we've maintained the financial health of our company since its inception, while still offering competitive rates to our customers. Our dedication to these principles, combined with our customer-focused approach, solidifies our position as a trusted and reliable car rental service.",
                services: [
                    {
                        title: "Extensive Car Selection",
                        desc: "Choose from our fleet of over 12,000 vehicles, offering everything from compact cars to luxury models, tailored to fit every journey."
                    },
                    {
                        title: "Convenient Booking",
                        desc: "Enjoy a hassle-free rental experience with our seamless online booking system, allowing you to reserve your vehicle anytime, from anywhere."
                    },
                    {
                        title: "Complete Peace of Mind",
                        desc: "Travel with confidence knowing our comprehensive insurance coverage and 24/7 roadside assistance have you covered every mile of your journey."
                    },
                ]
            },
            whatWeDo: {
                title: "What We Do",
                desc: "At Velocity Rent, we are committed to providing exceptional car rental services to our customers nationwide. We understand that everyone's travel needs are unique, and that's why we offer an extensive fleet of over 12,000 vehicles, including compact cars, spacious SUVs, and luxury models, ensuring you find the perfect fit for your journey. We take pride in our seamless online booking system, allowing customers to reserve a car from any location at any time. Our flexible pick-up and drop-off options offer the convenience you need to start your trip off right. And with our competitive rates, you'll get the best value for your money. But we don't just stop at providing you a car. We also offer comprehensive insurance coverage for peace of mind, and our 24/7 roadside assistance is always there to support you in case of any on-road issues. We are particularly attentive to the cleanliness and sanitization of our vehicles. We follow strict cleaning protocols to ensure every car is thoroughly cleaned and disinfected before each rental, providing a safe and comfortable travel environment. At Velocity Rent, we do more than just rent cars - we provide a smooth, safe, and stress-free travel experience from start to finish. Your journey matters to us, and we are here to make it memorable.",
                services: ["Extensive Vehicle Selection", "Easy Online Booking", "Flexible Pick-up and Drop-off", "Affordable Insurance Options", "24/7 Roadside Assistance", "Comprehensive Cleaning & Sanitization"]
            },
        },
        contact: {
            title: "Contact Us",
            desc: "At Velocity Rent, your questions and feedback matter to us. Whether you need help with reservations, have a question about our services, or simply want to share your experience, we are here for you. Feel free to drop us an email or call our customer service line for prompt assistance. Alternatively, you can also use the live chat feature on our website for real-time support. We look forward to hearing from you and ensuring your car rental needs are met with utmost satisfaction.",
            mapEmbedUrl: "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d10167.550174568947!2d-73.99430236638482!3d40.743749522813346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1snew%20york%20tech!5e0!3m2!1sen!2str!4v1683791084424!5m2!1sen!2str",
        },
        vehicleDetails: {
            title: "Vehicle Details",

        },
        vehicles: {
            title: "Our Vehicles",
        },
        userProfile: {
            title: "Profile",
        },
        userReservations: {
            title: "Reservations",
        },
        userReservationsDetails: {
            title: "Reservation Details",
        },
        adminPanel: {
            title: "Admin Panel",
            listItems: {
                dashboard: "Dashboard",
                panelUsers: "Users",
                vehicles: "Vehicles",
                reservations: "Reservations",
                panelContactMessages: "Contact Messages",
                website: "Website",
                panelLogout: "Logout",
            }
        },
        adminUserPage: {
            title: "Users",
        },
        adminUserDetails: {
            title: "User Details",
            roles: [
                {
                    id: 1,
                    name: "Customer",
                    value: "Customer",
                },
                {
                    id: 2,
                    name: "Administrator",
                    value: "Administrator",
                }
            ],
        },
        adminVehiclePage: {
            title: "Vehicles",
        },
        adminVehicleDetails: {
            title: "Vehicle Details",
            fuelTypes: [
                {
                    id: 1,
                    name: "Diesel",
                    value: "Diesel"
                },
                {
                    id: 2,
                    name: "Gasoline",
                    value: "Gasoline"
                },
                {
                    id: 3,
                    name: "Hybrid",
                    value: "Hybrid"
                },
                {
                    id: 4,
                    name: "Electricity",
                    value: "Electricity"
                },
                {
                    id: 5,
                    name: "LPG",
                    value: "LPG"
                },
                {
                    id: 6,
                    name: "CNG",
                    value: "CNG"
                },
                {
                    id: 7,
                    name: "Hydrogen",
                    value: "Hydrogen"
                }
            ],
            transmissionTypes: [
                {
                    id: 1,
                    name: "Automatic",
                    value: "Automatic"
                },
                {
                    id: 2,
                    name: "Manual",
                    value: "Manual"
                },
                {
                    id: 3,
                    name: "Tiptronic",
                    value: "Tiptronic"
                }
            ],
            airConditioningTypes: [
                {
                    id: 0,
                    name: "No",
                    value: false
                },
                {
                    id: 1,
                    name: "Yes",
                    value: true
                }
            ],
        },
        adminReservationPage: {
            title: "Reservations",
        },
        adminReservationDetails: {
            title: "Reservation Details",
            status: [
                {
                    id: 1,
                    name: "CREATED",
                    value: "CREATED"
                },
                {
                    id: 2,
                    name: "CANCELLED",
                    value: "CANCELLED"
                },
                {
                    id: 3,
                    name: "DONE",
                    value: "DONE"
                }
            ]
        },
        adminContactMessagesPage: {
            title: "Contact Messages",
        },
        slider: [
            {
                id: 1,
                image: "slider1.jpg",
                title: "Car Rent Service In Your City",
                desc: "We offer rental deals at large airports like Norman Y. Mineta San Jose International Airport"
            },
            {
                id: 2,
                image: "slider2.jpg",
                title: "Quality Cars with Unlimited Miles",
                desc: "We also have convertible, luxury and premium options if you want a more upscale rental"
            }
        ],
        mapUrl: "https://goo.gl/maps/aekRiJbXVYuqVMxp7",
        footerText: "Check out our new fleet of cars, latest offers, our advantages and free extras online. Choose from one of our new car models.",
        errors: {
            notFound: {
                code: "404",
                title: "Oops! Page Not Found",
                desc: "The page you are looking for does not exist.",
            },
            forbidden: {
                code: "403",
                title: "Oops! Forbidden Access",
                desc: "You are not authorized to access this page.",
            }
        }
    },
    API_URL: 'https://carrental-v3-backend.herokuapp.com',
    endpoints: {
        auth: {
            login: "/login",
            register: "/register",
        },
        cars: {
            visitors: '/car/visitors',
            admin: '/car/admin',
        },
        contactmessage: "/contactmessage",
        files: {
            get: '/files',
            upload: '/files/upload',
            deleteFile: '/files',
            download: '/files/download',
            display: "/files/display"
        },
        reports: {
            users: "/excel/download/users",
            reservations: "/excel/download/reservations",
            cars: "/excel/download/cars",
        },
        reservations: "/reservations",
        userPath: "/user"
    },
    routes: {
        about: 'about',
        admin: {
            admin: "admin",
            contactMessages: 'contact-messages',
            dashboard: 'dashboard',
            adminReservations: 'reservations',
            adminUsers: 'users',
            adminVehicles: 'vehicles',
        },
        contact: 'contact',
        home: '/',
        login: 'login',
        privacyPolicy: 'privacy-policy',
        register: 'register',
        user: {
            userRoute: "user",
            reservations: 'reservations',
        },
        errors: {
            notFoundRoute: '*',
            forbiddenRoute: 'forbidden',
        },
        vehicles: 'vehicles',
    },
}