import React from 'react';
import { PageHeader, Spacer, Vehicles } from "../../../components";
import { config } from '../../../config';

const { projectDetails: { vehicles: { title } } } = config;

const VehiclesPage = () => {

    return (
        <>
            <PageHeader title={title} />
            <Spacer />
            <Vehicles />
            <Spacer />
        </>
    )
}

export default VehiclesPage