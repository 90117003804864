import React from 'react';
import { Spinner, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { formatDateTime } from '../../../../utils';

const UserReservationsTable = ({ loading, reservations }) => {
    const navigate = useNavigate();

    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Vehicle</th>
                    <th>Pick Up</th>
                    <th>Drop Off</th>
                </tr>
            </thead>
            <tbody>
                {
                    loading
                    && (
                        <tr>
                            <td colSpan={4} className='text-center'>
                                <Spinner animation='border' size='sm' />
                            </td>
                        </tr>
                    )
                }
                {
                    reservations.map((reservation, index) => (
                        <tr
                            key={
                                Object.keys(reservation).length > 0
                                    ? reservation.id
                                    : index
                            }
                            onClick={() => navigate(`${reservation.id}`)}
                            style={{ cursor: 'pointer' }}
                        >
                            <td>{index + 1}</td>
                            <td>{reservation?.car?.model}</td>
                            <td>
                                {reservation?.pickUpLocation} — {formatDateTime(reservation?.pickUpTime)}
                            </td>
                            <td>
                                {reservation?.dropOffLocation} — {formatDateTime(reservation?.dropOffTime)}
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </Table>
    )
}

export default UserReservationsTable;