import axios from "axios";
import { authHeader } from "./auth-header";
import { config } from "../config";

const { API_URL, endpoints: { cars: { visitors, admin }, files: { upload, deleteFile }, reports } } = config;

// VISITOR ENDPOINTS

export const getVehicles = async () => {
    const response = await axios.get(`${API_URL + visitors}/all`);
    return response.data;
};

export const getVehiclesByPage = async (
    page = 0,
    size = 6,
    sort = "model",
    direction = "ASC"
) => {
    const response = await axios.get(`${API_URL + visitors}/pages?page=${page}&size=${size}&sort=${sort}&direction=${direction}`);
    return response.data;
};

export const getVehicleById = async (id) => {
    const response = await axios.get(`${API_URL + visitors}/${id}`);
    return response.data;
};


// ADMIN ENDPOINTS

export const addVehicle = async (imageId, vehicle) => {
    const response = await axios.post(`${API_URL + admin}/${imageId}/add`, vehicle,
        {
            headers: authHeader()
        });
    return response.data;
};

export const updateVehicle = async (vehicleId, imageId, vehicle) => {
    const response = await axios.put(`${API_URL + admin}/auth?id=${vehicleId}&imageId=${imageId}`, vehicle,
        {
            headers: authHeader()
        });
    return response.data;
};

export const deleteVehicle = async (vehicleId) => {
    const response = await axios.delete(`${API_URL + admin}/${vehicleId}/auth`,
        {
            headers: authHeader()
        });
    return response.data;
};

export const uploadVehicleImage = async (file) => {
    const response = await axios.post(`${API_URL + upload}`, file,
        {
            headers: { ...authHeader(), "Content-Type": "multipart/form-data" }
        });
    // this content-type is a standard for uploading files
    return response.data;
};

export const deleteVehicleImage = async (imageId) => {
    const response = await axios.delete(`${API_URL + deleteFile}/${imageId}`,
        {
            headers: authHeader()
        });
    return response.data;
};

export const downloadVehiclesReport = async () => {
    const response = await axios.get(`${API_URL + reports.cars}`,
        {
            headers: {
                ...authHeader(),
                "Content-Type":
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            },
            responseType: "blob",
        });
    return response.data;
};