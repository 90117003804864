import React, { useState } from 'react';
import { config } from '../../../config';
import { Carousel } from 'react-bootstrap';
import { Loading } from "../../";
import { Link } from 'react-router-dom';
import "./style.scss";

const { API_URL, endpoints: { files: { display } }, routes: { admin: { admin, adminVehicles } } } = config;

const AdminCarousel = ({ vehicles }) => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (
        <Carousel
            className='admin-carousel'
            activeIndex={index}
            onSelect={handleSelect}
        >
            {
                Object.keys(vehicles).length > 0
                    ? vehicles?.content?.map((vehicle) => (
                        <Carousel.Item key={vehicle?.id}>
                            <h2>{vehicle?.model}</h2>
                            <div>
                                <Link
                                    to={`/${admin}/${adminVehicles}/${vehicle?.id}`}
                                >
                                    <img
                                        src={`${API_URL + display}/${vehicle?.image[0]}`}
                                        alt={vehicle?.model}
                                        width={500}
                                        title={vehicle?.model}
                                    />
                                </Link>
                            </div>
                        </Carousel.Item>
                    ))
                    : <Loading height="400px" />
            }
        </Carousel>
    )
}

export default AdminCarousel