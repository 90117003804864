import React from 'react';
import { config } from '../../../../config';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const { API_URL, endpoints: { files: { display } } } = config;

const UserReservationDetailsPanel = ({ car }) => {

    const navigate = useNavigate();

    return (
        <>
            <h2>{car?.model}</h2>
            <img
                src={`${API_URL + display}/${car?.image}`}
                alt={car?.model}
                title={car?.model}
                className='img-fluid'
            />
            <Button
                onClick={() => navigate(-1)}
            >
                Back To Reservations
            </Button>
        </>
    )
}

export default UserReservationDetailsPanel