import React, { useEffect, useState } from 'react';
import { FormTypes, Loading, SectionHeader } from "../../../../components";
import { Button, ButtonGroup, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { config } from '../../../../config';
import { useFormik } from 'formik';
import { adminReservationDetailsFormValidationSchema, combineDateAndTime, getDate, getTime, swalQuestion, swalToast } from '../../../../utils';
import { deleteReservation, getReservationById, getVehicles, updateReservation } from '../../../../api';
import { TurnSlightLeft } from '@mui/icons-material';
import "./style.scss";

const { routes: { admin: { admin, adminReservations, adminUsers } }, projectDetails: { adminReservationDetails: { status } } } = config;

const AdminReservationDetailsPage = () => {
    const [loading, setLoading] = useState(true);
    const [deleting, setDeleting] = useState(false);
    const [saving, setSaving] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const { reservationId } = useParams();
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        pickUpLocation: "",
        dropOffLocation: "",
        pickUpDate: "",
        pickUpTime: "",
        dropOffDate: "",
        dropOffTime: "",
        carId: "",
        status: "",
        userId: "",
    });

    const onSubmit = async (values) => {
        setSaving(true);

        const dto = {
            pickUpTime: combineDateAndTime(values.pickUpDate, values.pickUpTime),
            dropOffTime: combineDateAndTime(values.dropOffDate, values.dropOffTime),
            pickUpLocation: values.pickUpLocation,
            dropOffLocation: values.dropOffLocation,
            status: values.status,
        };

        try {
            await updateReservation(values.carId, reservationId, dto);
            swalToast("Reservation updated successfully!", 'success');
        } catch (error) {
            swalToast(error.response.data.message, 'error');
        } finally {
            setSaving(false);
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema: adminReservationDetailsFormValidationSchema,
        onSubmit,
        enableReinitialize: true
    });

    const removeReservation = async () => {
        setDeleting(true);
        try {
            await deleteReservation(reservationId);
            swalToast("Reservation deleted successfully!", 'success');
            navigate(`/${admin}/${adminReservations}`)
        } catch (error) {
            swalToast(error.response.data.message, 'error');
        } finally {
            setDeleting(false);
        }
    };

    const handleDelete = () => {
        swalQuestion("Are you sure you want to delete this reservation?", "You will not be able to recover it!")
            .then((result) => {
                if (result.isConfirmed) {
                    removeReservation();
                }
            })
    }


    const loadData = async () => {
        try {
            const reservationsData = await getReservationById(reservationId);
            const vehiclesData = await getVehicles();

            const dto = {
                ...reservationsData,
                pickUpDate: getDate(reservationsData.pickUpDate),
                dropOffDate: getDate(reservationsData.dropOffDate),
                pickUpTime: getTime(reservationsData.pickUpTime),
                dropOffTime: getTime(reservationsData.dropOffTime),
                carId: reservationsData.car.id,
            };

            setInitialValues(dto);
            setVehicles(vehiclesData);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };


    let vehiclesOptions = vehicles.map((vehicle) => ({
        id: vehicle?.id,
        value: vehicle?.id,
        name: vehicle?.model
    }));


    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return loading
        ? (
            <Loading height={500} />
        ) : (
            <>
                <SectionHeader title1="Reservation" title2="Details" />
                <Form
                    noValidate
                    onSubmit={formik.handleSubmit}
                    className='admin-reservation-details-page'
                >
                    <Row>
                        <h2>reservation id: {reservationId}</h2>
                    </Row>
                    <Row>
                        <Col md={6} lg={4}>
                            <FormTypes
                                formik={formik}
                                name="pickUpLocation"
                                label="Pick Up Location"
                            />
                        </Col>
                        <Col md={6} lg={4}>
                            <FormTypes
                                formik={formik}
                                name="dropOffLocation"
                                label="Drop Off Location"
                            />
                        </Col>
                        <Col md={6} lg={4}>
                            <FormTypes
                                formik={formik}
                                name="pickUpDate"
                                label="Pick Up Date"
                                type="date"
                            />
                        </Col>
                        <Col md={6} lg={4}>
                            <FormTypes
                                formik={formik}
                                name="pickUpTime"
                                label="Pick Up Time"
                                type="time"
                            />
                        </Col>
                        <Col md={6} lg={4}>
                            <FormTypes
                                formik={formik}
                                name="dropOffDate"
                                label="Drop Off Date"
                                type="date"
                            />
                        </Col>
                        <Col md={6} lg={4}>
                            <FormTypes
                                formik={formik}
                                name="dropOffTime"
                                label="Drop Off Time"
                                type="time"
                            />
                        </Col>
                        <Col md={6} lg={4}>
                            <FormTypes
                                formik={formik}
                                name="carId"
                                label="Vehicle"
                                type="select"
                                itemsArr={vehiclesOptions}
                            />
                        </Col>
                        <Col md={6} lg={4}>
                            <FormTypes
                                formik={formik}
                                name="status"
                                label="Status"
                                type="select"
                                itemsArr={status}
                            />
                        </Col>
                    </Row>
                    <div className='buttons-container'>
                        <div className='go-to-customer'>
                            <Button
                                as={Link}
                                to={`/${admin}/${adminUsers}/${formik.values.userId}`}
                            >
                                <TurnSlightLeft /> go to customer
                            </Button>
                        </div>
                        <ButtonGroup>
                            <Button
                                type='submit'
                                disabled={!(formik.dirty && formik.isValid) || saving}
                            >
                                {saving && <Spinner animation='border' size='sm' />} save
                            </Button>
                            <Button
                                onClick={() => navigate(`/${admin}/${adminReservations}`)}
                            >
                                cancel
                            </Button>
                            <Button
                                disabled={deleting}
                                onClick={handleDelete}
                            >
                                {deleting && <Spinner animation='border' size='sm' />} delete
                            </Button>
                        </ButtonGroup>
                    </div>
                </Form>
            </>
        )
};

export default AdminReservationDetailsPage;