import React, { useEffect, useRef, useState } from 'react';
import { config } from '../../../../config';
import { useNavigate, useParams } from 'react-router-dom';
import { FormTypes, Loading } from '../../../../components';
import { useFormik } from 'formik';
import { adminVehicleFormValidationSchema, swalQuestion, swalToast } from '../../../../utils';
import { deleteVehicle, deleteVehicleImage, getVehicleById, updateVehicle, uploadVehicleImage } from '../../../../api';
import { Badge, Button, Col, Row, Form, Alert, ButtonGroup, Spinner } from 'react-bootstrap';
import "./style.scss";

const { API_URL, endpoints: { files: { display } }, projectDetails: { adminVehicleDetails: { transmissionTypes, airConditioningTypes, fuelTypes } }, routes: { admin: { admin, adminVehicles } } } = config;

let imageChanged = false;

const AdminVehicleDetailsPage = () => {
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [imageSrc, setImageSrc] = useState("");
    const fileImageRef = useRef();
    const { vehicleId } = useParams();
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        model: "",
        doors: "",
        seats: "",
        luggage: "",
        transmission: "",
        airConditioning: "",
        fuelType: "",
        age: "",
        pricePerHour: "",
        image: [],
    });


    const onSubmit = async (values) => {
        setUpdating(true);
        try {
            let imageId = values.image[0];

            if (imageChanged) {
                await deleteVehicleImage(imageId);

                const newImageFile = fileImageRef.current.files[0];
                const formData = new FormData();
                formData.append("file", newImageFile);

                const data = await uploadVehicleImage(formData);
                imageId = data.id;
                imageChanged = false;
            };

            delete values.image;
            await updateVehicle(vehicleId, imageId, values);
            swalToast("Vehicle updated successfully!", "success");
            navigate(`/${admin}/${adminVehicles}`)
        } catch (error) {
            swalToast(error.response.data.message, "error");
        } finally {
            setUpdating(false);
        }
    }

    const handleChangeImage = () => {
        const file = fileImageRef.current.files[0];
        if (!file) return;

        // to show image preview
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
            setImageSrc(reader.result);
        };

        imageChanged = true;
    };


    const formik = useFormik({
        initialValues,
        validationSchema: adminVehicleFormValidationSchema,
        onSubmit,
        enableReinitialize: true
    });


    const loadData = async () => {
        try {
            const vehicleData = await getVehicleById(vehicleId);
            setInitialValues(vehicleData);
            setImageSrc(`${API_URL + display}/${vehicleData.image[0]}`);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const removeVehicle = async () => {
        setDeleting(true);
        try {
            await deleteVehicle(vehicleId);
            swalToast("Vehicle deleted successfully!", "success");
            navigate(`/${admin}/${adminVehicles}`)
        } catch (error) {
            swalToast(error.response.data.message, "error")
        } finally {
            setDeleting(false);
        }
    };

    const handleDelete = () => {
        swalQuestion("Are you sure you want to delete this vehicle?", "You won't be able to revert this action!")
            .then((result) => {
                if (result.isConfirmed) {
                    removeVehicle();
                }
            })
    };

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return loading
        ? <Loading height={500} />
        : (
            <Form
                noValidate
                onSubmit={formik.handleSubmit}
            >
                <div className="admin-vehicle-details-form">
                    <fieldset disabled={formik.values.builtIn}>
                        <Row className='align-items-center'>
                            <Col xl={3} className='image-area'>
                                {
                                    imageSrc
                                    && <img
                                        src={imageSrc}
                                        alt={formik?.values?.model}
                                        title={formik?.values?.model}
                                    />
                                }
                                <Form.Group controlId="fileSelect">
                                    <Form.Control
                                        type="file"
                                        name="image"
                                        accept=".jpg,.jpeg,.png"
                                        onChange={handleChangeImage}
                                        className="d-none"
                                    />
                                    <div className='cover'>
                                        <Button as={Form.Label}>
                                            Select Image
                                        </Button>
                                    </div>
                                </Form.Group>
                                <Badge bg='danger' className='image-error'>
                                    {formik.errors.image}
                                </Badge>
                            </Col>
                            <Col xl={9}>
                                <Row className='row-cols-1 row-cols-md-2 row-cols-lg-3'>
                                    <FormTypes
                                        formik={formik}
                                        label='Model'
                                        name='model'
                                        asGroup={Col}
                                    />
                                    <FormTypes
                                        formik={formik}
                                        label='Doors'
                                        name='doors'
                                        type='number'
                                        asGroup={Col}
                                    />
                                    <FormTypes
                                        formik={formik}
                                        label='Seats'
                                        name='seats'
                                        type='number'
                                        asGroup={Col}
                                    />
                                    <FormTypes
                                        formik={formik}
                                        label='Luggage'
                                        name='luggage'
                                        type='number'
                                        asGroup={Col}
                                    />

                                    <FormTypes
                                        formik={formik}
                                        label="Age"
                                        name="age"
                                        type="number"
                                        asGroup={Col}
                                    />
                                    <FormTypes
                                        formik={formik}
                                        label="Price Per Hour"
                                        name="pricePerHour"
                                        type="number"
                                        asGroup={Col}
                                    />
                                    <FormTypes
                                        formik={formik}
                                        label="Transmission"
                                        name="transmission"
                                        type="select"
                                        asGroup={Col}
                                        itemsArr={transmissionTypes}
                                    />
                                    <FormTypes
                                        formik={formik}
                                        label="Air Conditioning"
                                        name="airConditioning"
                                        type="select"
                                        asGroup={Col}
                                        itemsArr={airConditioningTypes}
                                    />
                                    <FormTypes
                                        formik={formik}
                                        label="Fuel Type"
                                        name="fuelType"
                                        type="select"
                                        asGroup={Col}
                                        itemsArr={fuelTypes}
                                    />
                                </Row>
                            </Col>
                        </Row>
                    </fieldset>
                    {
                        formik.values.builtIn
                        &&
                        <Alert variant='warning' className='mt-5'>
                            Built-in vehicles cannot be deleted or updated.
                        </Alert>
                    }
                    <div className="text-end">
                        <ButtonGroup>
                            <Button
                                onClick={() => navigate(`/${admin}/${adminVehicles}`)}
                            >
                                cancel
                            </Button>
                            {
                                !formik.values.builtIn
                                && (
                                    <>
                                        <Button
                                            type='submit'
                                            variant='warning'
                                            disabled={!(formik.dirty && formik.isValid) || updating}
                                        >
                                            {updating && <Spinner animation='border' size='sm' />}  update
                                        </Button>
                                        <Button
                                            variant='danger'
                                            disabled={deleting}
                                            className='text-info'
                                            onClick={handleDelete}
                                        >
                                            {deleting && <Spinner animation='border' size='sm' />} delete
                                        </Button>
                                    </>
                                )
                            }
                        </ButtonGroup>
                    </div>
                </div>
            </Form>
        )
};

export default AdminVehicleDetailsPage;