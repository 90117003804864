import React from 'react'
import { config } from '../../../../config';
import { BrunchDining, LocalGasStation, DoorFront, AirlineSeatReclineNormal } from "@mui/icons-material";
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "./style.scss";

const { API_URL, routes: { vehicles }, endpoints: { files: { display } } } = config;

const VehicleCard = (props) => {
    const { id, image, model, transmission, fuelType, doors, seats } = props;
    return (
        <div className='vehicle-card'>
            <div className='image'>
                <img src={`${API_URL + display}/${image}`}
                    alt={model}
                    title={model}
                    className='img-fluid'
                />
            </div>
            <h4>{model}</h4>
            <div className='details'>
                <div><BrunchDining /> {transmission}</div>
                <div><LocalGasStation /> {fuelType}</div>
                <div><DoorFront /> {doors} doors</div>
                <div><AirlineSeatReclineNormal /> {seats} seats</div>
            </div>
            <Button
                variant='primary'
                as={Link}
                to={`/${vehicles}/${id}`}
            >
                Rent Now
            </Button>
        </div>
    )
}

export default VehicleCard