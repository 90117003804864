import axios from "axios";
import { authHeader } from "./auth-header";
import { config } from "../config";

const { API_URL, endpoints: { reservations, reports } } = config;

// VISITOR ENDPOINTS
export const isVehicleAvailable = async (dto) => {
    const { carId, pickUpDateTime, dropOffDateTime } = dto;
    const response = await axios.get(`${API_URL + reservations}/auth?carId=${carId}&pickUpDateTime=${pickUpDateTime}&dropOffDateTime=${dropOffDateTime}`, {
        headers: authHeader()
    });
    return response.data;
};

export const createReservation = async (carId, reservation) => {
    const response = await axios.post(`${API_URL + reservations}/add?carId=${carId}`, reservation, {
        headers: authHeader()
    });
    return response.data;
};

export const getReservationsByPage = async (
    page = 0,
    size = 20,
    sort = "pickUpTime",
    direction = "DESC"
) => {
    const response = await axios.get(`${API_URL + reservations}/auth/all?page=${page}&size=${size}&sort=${sort}&direction=${direction}`, {
        headers: authHeader()
    });
    return response.data;
};

export const getReservationById = async (id) => {
    const response = await axios.get(`${API_URL + reservations}/${id}/auth`, {
        headers: authHeader()
    });
    return response.data;
};

// ADMIN ENDPOINTS

export const getReservationsByPageAdmin = async (
    page = 0,
    size = 20,
    sort = "id",
    direction = "DESC"
) => {
    const response = await axios.get(`${API_URL + reservations}/admin/all/pages?page=${page}&size=${size}&sort=${sort}&direction=${direction}`, {
        headers: authHeader()
    });
    return response.data;
};

export const downloadReservationsReport = async () => {
    const response = await axios.get(`${API_URL + reports.reservations}`, {
        headers: {
            ...authHeader(),
            "Content-Type":
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "blob"
    });
    return response.data;
};

export const getReservationByIdAdmin = async (id) => {
    const response = await axios.get(`${API_URL + reservations}/${id}/admin`, {
        headers: authHeader()
    });
    return response.data;
};

export const updateReservation = async (carId, reservationId, reservation) => {
    const response = await axios.put(`${API_URL + reservations}/admin/auth?carId=${carId}&reservationId=${reservationId}`, reservation, {
        headers: authHeader()
    });
    return response.data;
};

export const deleteReservation = async (id) => {
    const response = await axios.delete(`${API_URL + reservations}/admin/${id}/auth`, {
        headers: authHeader()
    });
    return response.data;
};