import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AdminSidebar } from '../../components';
import { useSelector } from 'react-redux';
import { config } from '../../config';
import './style.scss';

const { routes: { errors: { forbiddenRoute } } } = config;

const AdminLayout = () => {
    const { user } = useSelector((state) => state.auth);

    if (!user || !user?.roles?.includes("Administrator")) return <Navigate to={`/${forbiddenRoute}`} />

    return (
        <div className='admin-layout'>
            <div className='admin-layout__sidebar'>
                <AdminSidebar />
            </div>
            <div className='admin-layout__content'>
                <Outlet />
            </div>
        </div>
    )
};

export default AdminLayout;