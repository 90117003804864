import React from 'react'
import { Card } from 'react-bootstrap'

const TeamMember = ({ image, name, title, desc }) => {
    return (
        <Card className='team-member text-center h-100'>
            <Card.Img
                variant="top"
                src={require(`../../../../../assets/images/team/${image}`)}
            />
            <Card.Body>
                <Card.Title>{name}</Card.Title>
                <Card.Subtitle> <em>{title}</em> </Card.Subtitle>
                <Card.Text className='mt-4 p-2' style={{ textAlign: "justify" }}>{desc}</Card.Text>
            </Card.Body>
        </Card>
    )
}

export default TeamMember