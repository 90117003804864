import React, { useEffect, useState } from 'react';
import { config } from '../../../../config';
import { useNavigate, useParams } from 'react-router-dom';
import { swalQuestion, swalToast } from '../../../../utils';
import { deleteMessage, getMessage } from '../../../../api';
import { Loading, SectionHeader } from "../../../../components";
import { ButtonGroup, Button, Spinner } from 'react-bootstrap';
import "./style.scss";

const { routes: { admin: { admin, contactMessages } } } = config;

const AdminContactMessageDetailsPage = () => {
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState({});
    const [deleting, setDeleting] = useState(false);
    const { contactMessageId } = useParams();
    const navigate = useNavigate();

    const loadData = async () => {
        try {
            const messageData = await getMessage(contactMessageId);
            setMessage(messageData);
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    };

    const removeMessage = async () => {
        setDeleting(true);
        try {
            await deleteMessage(contactMessageId);
            swalToast('Message deleted successfully!', 'success');
            navigate(`/${admin}/${contactMessages}`)
        } catch (error) {
            swalToast(error.response.data.message, 'error');
        } finally {
            setDeleting(false);
        }
    }

    const handleDelete = () => {
        swalQuestion("Are you sure you want to delete this message?", "You won't be able to recover it!")
            .then((result) => {
                if (result.isConfirmed) {
                    removeMessage();
                }
            })
    };

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return loading
        ? <Loading height={500} />
        : (
            <div className='admin-contact-message-details-page'>
                <SectionHeader title1="Message" title2="Details" />
                <div className='content'>
                    <table>
                        <tbody>
                            <tr>
                                <td>ID</td>
                                <td>:</td>
                                <td>{message?.id}</td>
                            </tr>
                            <tr>
                                <td>Name</td>
                                <td>:</td>
                                <td>{message?.name}</td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>:</td>
                                <td>{message?.email}</td>
                            </tr>
                            <tr>
                                <td>Subject</td>
                                <td>:</td>
                                <td>{message?.subject}</td>
                            </tr>
                            <tr>
                                <td>Message</td>
                                <td>:</td>
                                <td>{message?.body}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='buttons-container'>
                    <ButtonGroup>
                        <Button
                            onClick={() => navigate(`/${admin}/${contactMessages}`)}
                        >
                            cancel
                        </Button>
                        <Button
                            onClick={handleDelete}
                            disabled={deleting}
                        >
                            {deleting && <Spinner animation='border' size='sm' />} delete
                        </Button>
                    </ButtonGroup>
                </div>
            </div>
        )
};

export default AdminContactMessageDetailsPage;