import React, { useState } from 'react';
import { registerFormInitialValues, registerFormValidationSchema, swalToast } from '../../../utils';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import "./style.scss";
import { config } from "../../../config";
import { register } from '../../../api';
import { Form, Button } from "react-bootstrap";
import { FormTypes, PasswordInput } from '../../../components';

const RegisterPage = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { routes: { login } } = config;

    const onSubmit = async (values) => {
        setLoading(true);
        try {
            await register(values);
            swalToast("You have successfully registered!", "success");
            navigate(`/${login}`)
        } catch (error) {
            console.log(error)
            swalToast(error.response.data.message, "error");
        } finally {
            setLoading(false);
        }
    };

    const formik = useFormik({
        initialValues: registerFormInitialValues,
        validationSchema: registerFormValidationSchema,
        onSubmit
    });

    return (
        <Form

            onSubmit={formik.handleSubmit}
            className="register-form"
        >
            <FormTypes
                formik={formik}
                name="firstName"
                label="First Name"
                placeholder="Enter first name"
            />
            <FormTypes
                formik={formik}
                name="lastName"
                label="Last Name"
                placeholder="Enter last name"
            />
            <FormTypes
                formik={formik}
                name="email"
                label="Email"
                placeholder="Enter email"
                type="email"
            />
            <PasswordInput
                formik={formik}
                name="password"
                label="Password"
                placeholder="Enter password"
            />
            <PasswordInput
                formik={formik}
                name="confirmPassword"
                label="Confirm Password"
                placeholder="Confirm password"
            />
            <FormTypes
                formik={formik}
                name="phoneNumber"
                label="Phone Number"
                placeholder="Enter phone number"
                asForm="ReactInputMask"
                mask="(999) 999-9999"
            />
            <FormTypes
                formik={formik}
                name="address"
                label="Address"
                placeholder="Enter address"
            />

            <FormTypes
                formik={formik}
                name="zipCode"
                label="Zip Code"
                placeholder="Enter zip code"
                type="number"
            />
            <Button
                variant="primary"
                type="submit"
                disabled={!(formik.dirty && formik.isValid) || loading}
                className='mb-3'
            >
                register
            </Button>
            <p>Already a member?</p>
            <Button
                variant="primary"
                onClick={() => navigate(`/${login}`)}
                disabled={loading}
            >
                login
            </Button>
        </Form>
    )
}

export default RegisterPage;