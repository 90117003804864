import React, { useEffect, useState } from 'react'
import { config } from "../../../config";
import { Link, useNavigate } from "react-router-dom";
import { Button, ButtonGroup, Spinner } from "react-bootstrap";
import { adminVehiclesColumns, swalToast } from "../../../utils";
import { downloadVehiclesReport, getVehiclesByPage } from '../../../api';
import fileDownload from 'js-file-download';
import DataTable from "react-data-table-component";
import { Loading } from '../../../components';
import "./style.scss";

const { projectDetails: { adminVehiclePage: { title } }, routes: { admin: { admin, adminVehicles } } } = config;

const AdminVehiclesPage = () => {
    const [loading, setLoading] = useState(true);
    const [downloading, setDownloading] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const navigate = useNavigate();

    const loadData = async (page) => {
        try {
            const vehiclesData = await getVehiclesByPage(page, perPage);
            const { content, totalElements } = vehiclesData;
            setVehicles(content);
            setTotalRows(totalElements);
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    };


    const handleDownload = async () => {
        setDownloading(true);
        try {
            const download = await downloadVehiclesReport();
            fileDownload(download, "vehicles.xlsx");
        } catch (error) {
            swalToast(error.response.data.message, 'error');
        } finally {
            setDownloading(false);
        }
    };

    const handlePerPageRowsChange = async (newPerPage, page) => {
        try {
            const vehiclesData = await getVehiclesByPage(page - 1, newPerPage);
            const { content } = vehiclesData;
            setVehicles(content);
            setPerPage(newPerPage);
        } catch (error) {
            console.log(error)
        }
    };

    const handlePageChange = (page) => {
        loadData(page - 1);
    };

    const handleRowClicked = (row) => {
        navigate(`/${admin}/${adminVehicles}/${row?.id}`)
    };

    useEffect(() => {
        loadData(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='admin-vehicles-page'>
            <ButtonGroup className='align-self-end'>
                <Button
                    as={Link}
                    to={`/${admin}/${adminVehicles}/new`}
                >
                    New Vehicle
                </Button>
                <Button
                    onClick={handleDownload}
                    disabled={downloading}
                >
                    {downloading && <Spinner animation='border' size='sm' />} Download Vehicle Report
                </Button>
            </ButtonGroup>
            <div className='admin-vehicles-table-container'>
                <DataTable
                    title={title}
                    columns={adminVehiclesColumns}
                    data={vehicles}
                    progressPending={loading}
                    progressComponent={<Loading height={500} />}
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangeRowsPerPage={handlePerPageRowsChange}
                    onChangePage={handlePageChange}
                    onRowClicked={handleRowClicked}
                    pagination
                    paginationServer
                    highlightOnHover
                />
            </div>
        </div>
    )
}

export default AdminVehiclesPage