import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { adminUserDetailsFormValidationSchema, swalQuestion, swalToast } from '../../../../utils';
import { FormTypes, Loading } from "../../../../components";
import { Alert, Button, ButtonGroup, Form, Row, Spinner } from 'react-bootstrap';
import { config } from '../../../../config';
import { deleteUser, getUserAdmin, updateUserAdmin } from '../../../../api';

const { projectDetails: { adminUserDetails: { roles } }, routes: { admin: { admin, adminUsers } } } = config;

const AdminUserDetailsPage = () => {
    const [loading, setLoading] = useState(true);
    const [deleting, setDeleting] = useState(false);
    const [updating, setUpdating] = useState(false);
    const { userId } = useParams();
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        phoneNumber: '',
        address: '',
        zipCode: '',
        roles: [],
    });

    const onSubmit = async (values) => {
        setUpdating(true);
        if (!values.password) delete values.password;
        const dto = {
            ...values,
            builtIn: false, // to prevent some registries from being deleted
        };
        try {
            await updateUserAdmin(userId, dto);
            swalToast("User updated successfully!", "success");
        } catch (error) {
            swalToast(error.response.data.message, "error");
        } finally {
            setUpdating(false);
        }
    };

    const loadData = async () => {
        try {
            const userData = await getUserAdmin(userId);
            setInitialValues(userData);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };


    const removeUser = async () => {
        setDeleting(true);
        try {
            await deleteUser(userId);
            swalToast("User deleted successfully!", "success");
            navigate(`/${admin}/${adminUsers}`);
        } catch (error) {
            swalToast(error.response.data.message, "error")
        } finally {
            setDeleting(false);
        }
    };


    const handleDelete = () => {
        swalQuestion("Are you sure you want to delete this user?", "You won't be able to revert this action!")
            .then((result) => {
                if (result.isConfirmed) {
                    removeUser();
                }
            })
    };

    const formik = useFormik({
        initialValues,
        validationSchema: adminUserDetailsFormValidationSchema,
        onSubmit,
        enableReinitialize: true
    });

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return loading ? (
        <Loading height={500} />
    ) : (
        <Form
            noValidate
            onSubmit={formik.handleSubmit}
            className='admin-user-details-form mt-5'
        >
            <fieldset disabled={formik.values.builtIn}>
                <Row className='row-cols-1 row-cols-md-2 row-cols-lg-3'>
                    <FormTypes
                        formik={formik}
                        label="First Name"
                        name="firstName"
                    />
                    <FormTypes
                        formik={formik}
                        label="Last Name"
                        name="lastName"
                    />
                    <FormTypes
                        formik={formik}
                        label="Email"
                        name="email"
                        type="email"
                    />
                    <FormTypes
                        formik={formik}
                        label="Phone Number"
                        name="phoneNumber"
                        asForm="ReactInputMask"
                        mask="(999) 999-9999"
                    />
                    <FormTypes
                        formik={formik}
                        label="Address"
                        name="address"
                    />
                    <FormTypes
                        formik={formik}
                        label="Zip Code"
                        name="zipCode"
                    />
                    <FormTypes
                        formik={formik}
                        label="Roles"
                        type="checkbox"
                        itemsArr={roles}
                        name="roles"
                    />
                </Row>
            </fieldset>
            {
                formik.values.builtIn
                && <Alert variant='warning'>
                    Built-in accounts cannot be deleted or updated.
                </Alert>
            }
            <div className='text-end'>
                <ButtonGroup>
                    <Button
                        onClick={() => navigate(-1)}
                    >
                        Cancel
                    </Button>
                    {
                        !formik.values.builtIn && (
                            <>
                                <Button
                                    type='submit'
                                    variant='warning'
                                    disabled={!(formik.dirty && formik.isValid)}
                                >
                                    {updating && <Spinner animation='border' size='sm' />}  Update
                                </Button>
                                <Button
                                    variant='danger'
                                    onClick={handleDelete}
                                    disabled={deleting}
                                    className='text-info'
                                >
                                    Delete
                                </Button>
                            </>
                        )
                    }
                </ButtonGroup>
            </div>
        </Form>
    )
}

export default AdminUserDetailsPage