import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/auth";
import reservationReducer from "./slices/reservation";

export default configureStore({
    reducer: {
        auth: authReducer,
        reservation: reservationReducer,
    }
});

export { loginSuccess, loginFailure, logout } from "./slices/auth";
export { setVehicle, setReservation } from "./slices/reservation";