import React from 'react'
import { ContactForm, ContactInfo, ContactMap, PageHeader, Spacer } from '../../../components';
import { Col, Container, Row } from 'react-bootstrap';
import { config } from '../../../config';
import "./style.scss";

const ContactPage = () => {
    const { projectDetails: { contact: { title, desc } } } = config;

    return (
        <>
            <PageHeader title={title} />
            <Spacer />
            <Container>
                <Row>
                    <Col md={6} className='contact-info-container'>
                        <p>{desc}</p>
                        <Spacer />
                        <ContactInfo />
                    </Col>
                    <Col md={6}>
                        <ContactForm />
                    </Col>
                </Row>
            </Container>
            <Spacer />
            <ContactMap />
        </>
    )
}

export default ContactPage