import React from 'react';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "./style.scss";

const DashboardCard = ({ icon, title, statistics, path }) => {
    return (
        <Link to={path}>
            <div className='dashboard-card' title={title}>
                <div className='icon'>
                    {icon}
                </div>
                <div className='content'>
                    <div className='title'>
                        <h3>
                            {title}
                        </h3>
                    </div>
                    <div className='item'>
                        {
                            statistics
                                ? <p>{statistics}</p>
                                : <Spinner animation='border' size='sm' />
                        }
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default DashboardCard